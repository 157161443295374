/*!
 * tabs.js
 */
const initTabs = () => {
    const tabToggleEls = document.querySelectorAll(".js-tab-toggle");
    const isActive = "is-active";
    const isBlocked = "is-blocked";
    const isHighlighted = "is-highlighted";
  
    const toggleTab = (toggler) => {
      if (toggler.dataset.tabEvent === "on") {
        return false;
      }
      toggler.setAttribute("data-tab-event", "on");
  
      toggler.addEventListener("click", (ev) => {
        ev.preventDefault();
  
        const activeToggler = document.querySelector(".js-tab-toggle.is-active");
        const tab = document.querySelector(toggler.getAttribute("href")) || document.querySelector(toggler.dataset.target);
        const activeTab = document.querySelector(".js-tab.is-active");
  
        if (activeToggler && activeTab) {
          activeToggler.classList.remove(isActive);
          activeTab.classList.remove(isActive);
          activeToggler.setAttribute("aria-expanded", false);
        }
        
        toggler.setAttribute("aria-expanded", true);
        toggler.classList.add(isActive);
        tab.classList.add(isActive);

      });
    };
  
  
    if (tabToggleEls.length) {
      tabToggleEls.forEach((tabToggleEl) => {
        toggleTab(tabToggleEl);
      });
    }
  };
  
  initTabs();