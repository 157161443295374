/*!
 * updatePlayersAfterDeadline()
 */
const updatePlayersAfterDeadline = () => {
  const alert = document.getElementById("alert-choiceAfterDeadline");
  const captainChoice = document.getElementById("js-captainChoice");
  // const captainChoiceSelect = captainChoice.querySelector("select");
  const playground = document.getElementById("js-playground");
  const isActive = "is-active";
  const isAlertActive = "is-alertActive";
  const isChosen = "is-chosen";
  const isLocked = "is-locked";
  const isPointsDetailedOpen = "is-pointsDetailedOpen";

  if (playground.classList.contains(isLocked)) {
    const players = document.querySelectorAll(".js-player");

    players.forEach((player, i) => {
      if (player.dataset.afterDeadlineEvents === "on") {
        return false;
      }
      player.setAttribute("data-after-deadline-events", "on");

      if (!player.classList.contains(isChosen)) {
        player.removeAttribute("title");
        player.removeAttribute("aria-label");

        player.addEventListener("click", () => {
          if (alert) {
            const alertText = alert.querySelector(".Alert-text");
            alertText.innerHTML = "Výber hráča po uzávierke nie je možný";
            document.body.classList.add(isAlertActive);
            alert.classList.add(isActive);
          }
        });
      } else {
        const playerHeaderContainer = player.querySelector(".Player-headerContainer");

        if (playerHeaderContainer) {
          playerHeaderContainer.removeAttribute("title");
          playerHeaderContainer.removeAttribute("aria-label");

          playerHeaderContainer.setAttribute("title", "Zobraziť bodový zisk");
          playerHeaderContainer.setAttribute("aria-label", "Zobraziť bodový zisk");

          playerHeaderContainer.removeAttribute("aria-controls");
          playerHeaderContainer.setAttribute("aria-haspopup", true);

          const pointsDetailed = player.querySelector(".js-pointsDetailed");

          if (pointsDetailed) {
            pointsDetailed.id = `popover-pointsDetailed-player-${i + 1}`;

            playerHeaderContainer.setAttribute("aria-controls", pointsDetailed.id);

            const handlePointsDetailedOn = (player, pointsDetailed, playerHeaderContainer) => {
              player.classList.add(isPointsDetailedOpen);
              pointsDetailed.classList.add(isActive);
              playerHeaderContainer.setAttribute("aria-expanded", true);
              playerHeaderContainer.setAttribute("title", "Skryť bodový zisk");
              playerHeaderContainer.setAttribute("aria-label", "Skryť bodový zisk");
            };

            const handlePointsDetailedOff = (player, pointsDetailed, playerHeaderContainer) => {
              player.classList.remove(isPointsDetailedOpen);
              pointsDetailed.classList.remove(isActive);
              playerHeaderContainer.setAttribute("aria-expanded", false);
              playerHeaderContainer.setAttribute("title", "Zobraziť bodový zisk");
              playerHeaderContainer.setAttribute("aria-label", "Zobraziť bodový zisk");
            };

            player.addEventListener("click", () => {
              if (player.classList.contains(isPointsDetailedOpen)) {
                handlePointsDetailedOff(player, pointsDetailed, playerHeaderContainer);
              } else {
                players.forEach((player) => {
                  const playerHeaderContainer = player.querySelector(".Player-headerContainer");
                  const pointsDetailed = player.querySelector(".js-pointsDetailed");

                  if (pointsDetailed) {
                    handlePointsDetailedOff(player, pointsDetailed, playerHeaderContainer);
                  }
                });
                handlePointsDetailedOn(player, pointsDetailed, playerHeaderContainer);
              }
            });
          }
        }
      }
    });

    // captainChoiceSelect.setAttribute("disabled", "disabled");
    // const edd = easydropdown(captainChoiceSelect);

    // captainChoice.addEventListener(
    //   "click",
    //   () => {
    //     if (alert) {
    //       const alertText = alert.querySelector(".Alert-text");
    //       alertText.innerHTML = "Výber kapitána po uzávierke nie je možný";
    //       document.body.classList.add(isAlertActive);
    //       alert.classList.add(isActive);
    //     }
    //   },
    //   true
    // );
  }
};

updatePlayersAfterDeadline();
