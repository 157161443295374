/*!
 * menu.js
 */
(function () {
  // Toggle Game menu, Logged user menu and Manage mini league menu
  const menuGame = document.getElementById("js-menu-game");
  const menuGameButton = document.getElementById("js-menu-game-toggle");
  const menuGameLinks = document.querySelectorAll(".js-menu-game-link");

  const menuLoggedUser = document.getElementById("js-menu-loggedUser");
  const menuLoggedUserButton = document.getElementById("js-menu-loggedUser-toggle");
  const menuLoggedUserLinks = document.querySelectorAll(".js-menu-loggedUser-link");

  const menuManageMiniLeague = document.getElementById("js-manageMiniLeague");
  const menuManageMiniLeagueButton = document.getElementById("js-manageMiniLeague-toggle");
  const menuManageMiniLeagueLinks = document.querySelectorAll(".js-manageMiniLeague-link");

  const isActive = "is-active";
  const isGameMenuOpen = "is-gameMenuOpen";
  const isHighlighted = "is-highlighted";
  const isSticky = "is-sticky";

  const inactiveMenu = (menuButton, menu) => {
    menu.classList.remove(isActive);
    menu.setAttribute("aria-hidden", true);
    menuButton.classList.remove(isActive);
    menuButton.setAttribute("aria-expanded", false);
    document.body.classList.remove(isGameMenuOpen);
  };

  // Handle Game menu
  if (menuGame && menuGameButton) {
    menuGameButton.addEventListener("click", () => {
      menuGame.classList.toggle(isActive);
      menuGameButton.classList.toggle(isActive);
      menuGameButton.setAttribute("aria-expanded", menuGame.classList.contains(isActive));
      document.body.classList.toggle(isGameMenuOpen, menuGame.classList.contains(isActive));

      const header = document.querySelector(".Header");
      if (window.innerWidth <= 576) {
        header.classList.remove(isSticky);
      }

      if (menuGame.classList.contains(isActive)) {
        menuGameButton.classList.remove(isHighlighted);
      }

      if (!menuGame.classList.contains(isActive) && document.querySelector(".js-subPage.is-active")) {
        header.classList.add(isSticky);
        menuGameButton.classList.add(isHighlighted);
      }
    });

    document.addEventListener("click", (ev) => {
      if (menuGame.classList.contains(isActive) && !menuGameButton.contains(ev.target)) {
        inactiveMenu(menuGameButton, menuGame);
      }
    });

    document.addEventListener("keydown", (ev) => {
      if (ev.key === "Escape") {
        inactiveMenu(menuGameButton, menuGame);
      }
    });
  }

  const inactiveGameMenuLinks = () => {
    menuGameLinks.forEach((link) => {
      link.classList.remove(isActive);
    });
  };

  if (menuGameLinks.length) {
    menuGameLinks.forEach((link) => {
      link.addEventListener("click", () => {
        inactiveGameMenuLinks();
        link.classList.toggle(isActive);
      });
    });
  }

  // Handle Logged user menu
  if (menuLoggedUserLinks.length) {
    menuLoggedUserLinks.forEach((link) => {
      link.addEventListener("click", () => {
        inactiveMenu(menuLoggedUserButton, menuLoggedUser);
      });
    });
  }

  if (menuLoggedUser && menuLoggedUserButton) {
    menuLoggedUserButton.addEventListener("click", () => {
      menuLoggedUser.classList.toggle(isActive);
      menuLoggedUser.setAttribute("aria-hidden", !menuLoggedUser.classList.contains(isActive));
      menuLoggedUserButton.classList.toggle(isActive);
      menuLoggedUserButton.setAttribute("aria-expanded", menuLoggedUser.classList.contains(isActive));
    });

    document.addEventListener("click", (ev) => {
      if (menuLoggedUser.classList.contains(isActive) && !menuLoggedUser.contains(ev.target) && !menuLoggedUserButton.contains(ev.target)) {
        inactiveMenu(menuLoggedUserButton, menuLoggedUser);
      }
    });

    document.addEventListener("keydown", (ev) => {
      if (ev.key === "Escape") {
        inactiveMenu(menuLoggedUserButton, menuLoggedUser);
      }
    });
  }

  // Handle Mini league menu
  if (menuManageMiniLeagueLinks.length) {
    menuManageMiniLeagueLinks.forEach((link) => {
      link.addEventListener("click", () => {
        inactiveMenu(menuManageMiniLeagueButton, menuManageMiniLeague);
      });
    });
  }

  if (menuManageMiniLeague && menuManageMiniLeagueButton) {
    menuManageMiniLeagueButton.addEventListener("click", () => {
      menuManageMiniLeague.classList.toggle(isActive);
      menuManageMiniLeague.setAttribute("aria-hidden", !menuManageMiniLeague.classList.contains(isActive));
      menuManageMiniLeagueButton.classList.toggle(isActive);
      menuManageMiniLeagueButton.setAttribute("aria-expanded", menuManageMiniLeague.classList.contains(isActive));
    });

    document.addEventListener("click", (ev) => {
      if (menuManageMiniLeague.classList.contains(isActive) && !menuManageMiniLeague.contains(ev.target) && !menuManageMiniLeagueButton.contains(ev.target)) {
        inactiveMenu(menuManageMiniLeagueButton, menuManageMiniLeague);
      }
    });

    document.addEventListener("keydown", (ev) => {
      if (ev.key === "Escape") {
        inactiveMenu(menuManageMiniLeagueButton, menuManageMiniLeague);
      }
    });
  }
})();
