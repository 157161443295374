/*!
 * popup.js
 */
const initPopup = () => {
  const popupToggleEls = document.querySelectorAll(".js-popup-toggle");
  const isActive = "is-active";
  const isBlocked = "is-blocked";
  const playground = document.getElementById("js-playground");
  const isLocked = "is-locked";
  const isHighlighted = "is-highlighted";
  const isSticky = "is-sticky";
  const menuGameButton = document.getElementById("js-menu-game-toggle");
  const header = document.querySelector(".Header");
  const subPage = "js-subPage";

  const togglePopup = (toggler) => {
    if (toggler.dataset.popupEvent === "on") {
      return false;
    }
    toggler.setAttribute("data-popup-event", "on");

    toggler.addEventListener("click", (ev) => {
      ev.preventDefault();

      if (playground.classList.contains(isLocked) && toggler.dataset.target.includes("playerChoice")) {
        return false;
      }

      const activeToggler = document.querySelector(".js-popup-toggle.is-active");
      const popup = document.querySelector(toggler.getAttribute("href")) || document.querySelector(toggler.dataset.target);
      const activePopup = document.querySelector(".js-popup.is-active");

      if (activeToggler && activePopup) {
        activeToggler.classList.remove(isActive);
        activeToggler.setAttribute("aria-expanded", false);
        activePopup.classList.remove(isActive);
        header.classList.remove(isSticky);
        menuGameButton.classList.remove(isHighlighted);
        document.body.classList.remove(isBlocked);
      }

      // If one popup has more togglers
      // if (activePopup && activePopup.id === popup.id) {
      //   return;
      // }

      toggler.classList.add(isActive);
      toggler.setAttribute("aria-expanded", true);
      popup.classList.add(isActive);

      // Sticky header and highlighted menu button on sub pages on mobile (xs) view
      if ((popup.classList.contains(isActive) || activePopup.classList.contains(subPage)) && popup.classList.contains(subPage) && window.innerWidth <= 576) {
        header.classList.add(isSticky);
        menuGameButton.classList.add(isHighlighted);
      }

      if (window.innerWidth <= 576) {
        document.body.classList.add(isBlocked);
      }

      closePopup(toggler, popup);
    });
  };

  const closePopup = (toggler, popup) => {
    const popupCloseEls = popup.querySelectorAll(".js-popup-close");
    popupCloseEls.forEach((popupCloseEl) => {
      popupCloseEl.addEventListener("click", (ev) => {
        ev.preventDefault();
        toggler.classList.remove(isActive);
        toggler.setAttribute("aria-expanded", false);
        popup.classList.remove(isActive);
        header.classList.remove(isSticky);
        menuGameButton.classList.remove(isHighlighted);
        document.body.classList.remove(isBlocked);
      });
    });

    const windowWidth = window.innerWidth;
    if (windowWidth > 576) {
      const myTeamGameMenuLink = document.querySelector(".js-menu-game-link");
      myTeamGameMenuLink.classList.add(isActive);
    }
  };

  if (popupToggleEls.length) {
    popupToggleEls.forEach((popupToggleEl) => {
      togglePopup(popupToggleEl);
    });
  }
};

const closePopup = () => {
  const activePopup = document.querySelector(".js-popup.is-active");
  const popupToggleEls = document.querySelectorAll(".js-popup-toggle");
  const isActive = "is-active";
  const isBlocked = "is-blocked";

  if (activePopup) {
    activePopup.classList.remove(isActive);
    document.querySelector(".Header").classList.remove("is-sticky");
    document.getElementById("js-menu-game-toggle").classList.remove("is-highlighted");
    document.body.classList.remove(isBlocked);
  }

  if (popupToggleEls.length) {
    popupToggleEls.forEach((popupToggleEl) => {
      if (popupToggleEl.classList.contains(isActive)) {
        popupToggleEl.classList.remove(isActive);
        popupToggleEl.setAttribute("aria-expanded", false);
      }
    });
  }

  const myTeamGameMenuLink = document.querySelector(".js-menu-game-link");
  myTeamGameMenuLink.classList.add(isActive);
};

document.addEventListener("click", (ev) => {
  if (ev.target.closest("#js-menu-game-toggle") || ev.target.closest(".js-menu-game-link") || ev.target.closest(".js-player") || ev.target.closest(".js-popup") || ev.target.closest(".js-modal")) {
    return false;
  } else {
    closePopup();
  }
});

initPopup();
