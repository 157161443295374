/*!
 * main.js
 */
document.addEventListener("DOMContentLoaded", () => {
  // Smooth scrolling to target
  const scrollToLinks = document.querySelectorAll(".js-scrollTo");

  if (scrollToLinks.length) {
    scrollToLinks.forEach((el) => {
      el.addEventListener("click", (ev) => {
        ev.preventDefault();

        const target = document.querySelector(el.getAttribute("href"));
        target.scrollIntoView({ behavior: "smooth" });
      });
    });
  }

  // Set current year to Footer text
  const currentYearEl = document.getElementById("js-setCurrentYear");
  if (currentYearEl) {
    const date = new Date();
    const currentYear = date.getFullYear();
    currentYearEl.innerText = currentYear;
  }

  // Easydropdown init (nice selects)
  easydropdown.all();
});

// Show secondary select in Fan league rankings
function showFanLeagueFilter(select) {
  const value = select.value;
  const selectItems = document.querySelectorAll('[id^="js-fanLeague-selectItem"]');

  selectItems.forEach((item) => {
    item.classList.add("u-display-none");
  });

  const activeSelectItem = document.querySelector(`#js-fanLeague-selectItem-${value}`);
  activeSelectItem.classList.remove("u-display-none");
}

// Show secondary select in Rankings
function showRankingsFilter(select) {
  const value = select.value;
  const selectItems = document.querySelectorAll('[id^="js-rankings-selectItem"]');

  selectItems.forEach((item) => {
    item.classList.add("u-display-none");
  });

  const activeSelectItem = document.querySelector(`#js-rankings-selectItem-${value}`);
  activeSelectItem.classList.remove("u-display-none");
}

// Pagination in Rankings with My team finder
function rankingsPagination() {
  const isVisible = "is-visible";
  let isMyTeam = false;
  const paginator = document.getElementById("js-rankings-pagination");
  let pageCounter = 1;
  const pageItems = 100;
  let rowsTotal = null;
  let visibleRows = 0;
  const menuLinkRankings = document.getElementById("js-menu-game-link--rankings");
  const rankingsSelectMain = document.getElementById("js-rankings-select-main");
  const rankingsSelectRound = document.getElementById("frm-ranking-filterForm-filterRound");
  const rankingsSelectSeason = document.getElementById("frm-ranking-filterForm-filterSeason");

  // scrollIntoView polyfill
  // seamless.polyfill();

  const init = () => {
    rowsTotal = document.querySelector("#snippet-ranking-rankingGrid tbody").children;

    if (rowsTotal.length > pageItems) {
      paginator.classList.remove("u-display-none");
    }

    const max = rowsTotal.length >= pageItems ? pageItems : rowsTotal.length;
    for (let i = 0; i < max; i++) {
      rowsTotal[i].classList.add(isVisible);
    }
  };
  init();

  const showMore = (pageCounter) => {
    let min = (pageCounter - 1) * pageItems;
    const max = rowsTotal.length >= pageCounter * pageItems ? pageCounter * pageItems : rowsTotal.length;

    if (visibleRows < min) {
      for (let i = 0; i < min; i++) {
        rowsTotal[i].classList.add(isVisible);
      }
    }

    if (visibleRows < max) {
      for (let i = min; i < max; i++) {
        rowsTotal[i].classList.add(isVisible);
      }
    }

    if (max === rowsTotal.length) {
      paginator.classList.add("u-display-none");
    }

    visibleRows = document.querySelectorAll("#snippet-ranking-rankingGrid tbody tr.is-visible").length;
  };

  paginator.addEventListener("click", () => {
    pageCounter++;
    showMore(pageCounter);
  });

  const checkMyTeam = () => {
    if (!isMyTeam) {
      const rankingGrid = document.querySelector("#snippet-ranking-rankingGrid tbody");

      if (rankingGrid.firstElementChild.classList.contains("ranking-strong")) {
        return false;
      }

      const myTeam = rankingGrid.querySelector(".ranking-strong");

      if (!myTeam) {
        return false;
      }

      const myTeamClone = myTeam.cloneNode(true);
      myTeamClone.classList.add(isVisible);
      rankingGrid.prepend(myTeamClone);
      isMyTeam = true;
    }
  };
  menuLinkRankings.addEventListener("click", checkMyTeam, { once: true });

  const mutationCallback = () => {
    init();
    isMyTeam = false;
    checkMyTeam();
  };

  const mutationObserver = new MutationObserver(mutationCallback);

  const targetNode = document.getElementById("snippet-ranking-rankingGrid");
  mutationObserver.observe(targetNode, { subtree: true, childList: true });
}
rankingsPagination();

// Remove Preload class on load (prevent CSS transitions)
window.addEventListener("load", () => {
  document.documentElement.classList.remove("Preload");
});

// Toggle Preload class on resize (prevent CSS transitions)
let resizeTimer;
window.addEventListener("resize", () => {
  document.documentElement.classList.add("Preload");
  clearTimeout(resizeTimer);
  resizeTimer = setTimeout(() => {
    document.documentElement.classList.remove("Preload");
  }, 400);
});
