/*!
 * modal.js
 */
(function () {
  const modalOpenEls = document.querySelectorAll(".js-modal-open");
  const isActive = "is-active";
  const isBlocked = "is-blocked";
  const isClosing = "is-closing";
  const isModalActive = "is-modalActive";

  const openModal = (modalOpenEl) => {
    modalOpenEl.addEventListener("click", (ev) => {
      ev.preventDefault();

      modalOpenEl.setAttribute("aria-expanded", true);

      const modal = document.querySelector(modalOpenEl.getAttribute("href")) || document.querySelector(modalOpenEl.dataset.target);
      const activeModal = document.querySelector(".js-modal.is-active");

      if (activeModal) {
        const activeModalID = activeModal.id;
        const activeModalOpenEl = document.querySelector(`[href='#${activeModalID}']`) || document.querySelector(`[data-target='#${activeModalID}']`);
        activeModalOpenEl.setAttribute("aria-expanded", false);
        activeModal.classList.remove(isActive);
        activeModal.removeAttribute("aria-modal");
      }

      modal.classList.add(isActive);
      modal.setAttribute("aria-modal", true);

      document.body.classList.add(isBlocked);
      document.body.classList.add(isModalActive);

      handleCloseModal(modalOpenEl, modal);
    });
  };

  const closeModal = (modalOpenEl, modal) => {
    if (modalOpenEl) {
      modalOpenEl.setAttribute("aria-expanded", false);
    }

    if (modal) {
      modal.classList.remove(isActive);
      modal.removeAttribute("aria-modal");
      modalClosingAnimation(modal);
    }

    document.body.classList.remove(isBlocked);
    document.body.classList.remove(isModalActive);
  };

  const handleCloseModal = (modalOpenEl, modal) => {
    const modalCloseEls = modal.querySelectorAll(".js-modal-close");

    modalCloseEls.forEach((modalCloseEl) => {
      modalCloseEl.addEventListener(
        "click",
        (ev) => {
          ev.preventDefault();
          closeModal(modalOpenEl, modal);
        },
        { once: true }
      );
    });

    modal.addEventListener("click", (ev) => {
      if (ev.target.id === modal.id) {
        closeModal(modalOpenEl, modal);
      }
    });

    document.addEventListener("keydown", (ev) => {
      if (ev.key === "Escape" && modal.classList.contains(isActive)) {
        closeModal(modalOpenEl, modal);
      }
    });
  };

  const modalClosingAnimation = (modal) => {
    modal.classList.add(isClosing);

    if ("AnimationEvent" in window) {
      modal.addEventListener(
        "animationend",
        () => {
          modal.classList.remove(isClosing);
        },
        { once: true }
      );
    } else {
      modal.classList.remove(isClosing);
    }
  };

  if (modalOpenEls.length) {
    modalOpenEls.forEach((modalOpenEl) => {
      openModal(modalOpenEl);
    });
  }

  document.addEventListener("click", (ev) => {
    if (ev.target.closest(".js-modal-close") || ev.target.classList.contains("js-modal")) {
      const activeModal = document.querySelector(".js-modal.is-active");
      closeModal(null, activeModal);
    }
  });

  document.addEventListener("keydown", (ev) => {
    const activeModal = document.querySelector(".js-modal.is-active");
    if (ev.key === "Escape" && activeModal) {
      closeModal(null, activeModal);
    }
  });
})();
