/*!
 * alert.js
 */
const initAlert = () => {
  const alerts = document.querySelectorAll(".js-alert");
  const isActive = "is-active";
  const isClosing = "is-closing";
  const isAlertActive = "is-alertActive";

  const closeAlert = (alert) => {
    const alertCloseEls = alert.querySelectorAll(".js-alert-close");

    if (alert.dataset.closeEvent === "on") {
      return false;
    }
    alert.setAttribute("data-close-event", "on");

    alertCloseEls.forEach((alertCloseEl) => {
      alertCloseEl.addEventListener("click", (ev) => {
        ev.preventDefault();
        alert.classList.remove(isActive);
        alertClosingAnimation(alert);
        document.body.classList.remove(isAlertActive);
      });
    });
  };

  const alertClosingAnimation = (alert) => {
    alert.classList.add(isClosing);
    alert.addEventListener(
      "animationend",
      () => {
        alert.classList.remove(isClosing);
      },
      { once: true }
    );
  };

  document.body.addEventListener("click", (ev) => {
    if (ev.target.classList.contains(isAlertActive)) {
      const activeAlert = document.querySelector(".js-alert.is-active");
      activeAlert.classList.remove(isActive);
      alertClosingAnimation(activeAlert);
      document.body.classList.remove(isAlertActive);
    }
  });

  if (alerts.length) {
    alerts.forEach((alert) => {
      closeAlert(alert);
    });
  }
};

initAlert();
