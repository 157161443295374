/*!
 * countdown.js
 */
let countdownInterval;
const initCountdown = () => {
  const countdownEls = document.querySelectorAll(".js-countdown");
  const playground = document.getElementById("js-playground");
  const isActive = "is-active";
  const isHidden = "is-hidden";
  const isLocked = "is-locked";

  if (countdownEls.length) {
    countdownEls.forEach((countdownEl) => {
      clearInterval(countdownInterval);
      countdown(countdownEl);
    });
  }

  function countdown(countdownEl) {
    const targetDate = countdownEl.dataset.countdownDate;
    const targetTimeInSecs = Date.parse(new Date(targetDate)) / 1000;
    const daysEl = countdownEl.querySelector(".js-countdown-days");
    const hoursEl = countdownEl.querySelector(".js-countdown-hours");
    const minutesEl = countdownEl.querySelector(".js-countdown-minutes");
    const secondsEl = countdownEl.querySelector(".js-countdown-seconds");
    const overEl = countdownEl.querySelector(".js-countdown-over");

    const calculateRemainingTime = () => {
      let currentTimeInSecs = Date.parse(new Date()) / 1000;
      let remainingTimeInSecs = parseInt(targetTimeInSecs - currentTimeInSecs);

      if (remainingTimeInSecs >= 0) {
        const days = parseInt(remainingTimeInSecs / 86400);
        remainingTimeInSecs = remainingTimeInSecs % 86400;

        const hours = parseInt(remainingTimeInSecs / 3600);
        remainingTimeInSecs = remainingTimeInSecs % 3600;

        const minutes = parseInt(remainingTimeInSecs / 60);
        remainingTimeInSecs = remainingTimeInSecs % 60;

        const seconds = parseInt(remainingTimeInSecs);

        if (daysEl) {
          if (days > 0) {
            daysEl.parentNode.classList.remove(isHidden);
            daysEl.innerText = days;
          } else {
            daysEl.parentNode.classList.add(isHidden);
          }
        }

        if (hoursEl) {
          if (hours > 0) {
            hoursEl.parentNode.classList.remove(isHidden);
            hoursEl.innerText = hours;
          } else {
            hoursEl.parentNode.classList.add(isHidden);
          }
        }

        if (minutesEl) {
          if (minutes > 0) {
            minutesEl.parentNode.classList.remove(isHidden);
            minutesEl.innerText = minutes;
          } else {
            minutesEl.parentNode.classList.add(isHidden);
          }
        }

        if (secondsEl && days < 1 && hours < 1 && minutes < 60) {
          if (seconds > 0) {
            secondsEl.parentNode.classList.remove(isHidden);
            secondsEl.innerText = seconds;
          } else {
            secondsEl.parentNode.classList.add(isHidden);
          }
        }

        countdownEl.classList.add(isActive);
      } else {
        overEl.classList.remove(isHidden);
        playground.classList.add(isLocked);
      }
    };

    countdownInterval = setInterval(calculateRemainingTime, 1000);
  }
};

initCountdown();
